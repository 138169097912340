import axios, { AxiosResponse } from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuth } from "@clerk/nextjs";
import { IDashboard } from "@/types/http/Dashboard";

type DashboardContextProps = {
  dashboards: Array<IDashboard>;
  setDashboards: (dashboards: Array<IDashboard>) => void;
  isLoadingDashboards: boolean;
  fetchDashboards: () => Promise<void>;
}
const DashboardContext = createContext({} as DashboardContextProps);

export const useDashboardContext = () => useContext(DashboardContext);

type Props = {
  children: JSX.Element | Array<JSX.Element> | React.ReactNode | Array<React.ReactNode>;
}
export const DashboardContextProvider = ({ children }: Props) => {
  const { orgId } = useAuth();
  const [dashboards, setDashboards] = useState<Array<IDashboard>>([]);
  const [isLoadingDashboards, setIsLoadingDashboards] = useState(true);
  
  const fetchDashboards = async () => {
    try {
      setIsLoadingDashboards(true);
      const { data }: AxiosResponse<Array<IDashboard>> = await axios.get("/api/dashboard");
      const fetchedDashboards = data.map(dashboard => ({
        ...dashboard,
        title: dashboard.title || "Untitled",
        widgets: dashboard.widgets || [],
        proactive_results: dashboard.proactive_results ? dashboard.proactive_results.sort((a, b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()) : [],
      }));
      
      setDashboards(fetchedDashboards.sort((a, b) => new Date(b.last_updated).valueOf() - new Date(a.last_updated).valueOf()));
    } catch (error) {
      console.error("Failed to fetch dashboards:", error);
    } finally {
      setIsLoadingDashboards(false);
    }
  };

  useEffect(() => {
    fetchDashboards();
  }, [orgId]);

  const props: DashboardContextProps = {
    dashboards,
    isLoadingDashboards,
    setDashboards,
    fetchDashboards
  };

  return (
    <DashboardContext.Provider value={props}>
      {children}
    </DashboardContext.Provider>
  );
};
