import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { ClerkProvider } from "@clerk/nextjs";
import { useRouter } from "next/router";
import { useEffect } from "react";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { NotificationProvider } from "@/lib/NotificationContext";
import Notification from "@/components/Notification";
import * as ga from "@/lib/ga";
import { UserContextProvider } from "@/context/UserContext";
import {AssistantContextProvider} from "@/context/AssistantContext";
import {DashboardContextProvider} from "@/context/DashboardContext";
import "react-tooltip/dist/react-tooltip.css";



// const options = {
//   api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
// };

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    }
  });
}

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const CLERK_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY;
  
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // Track page views
    const handleRouteChange2 = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange2);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange2);
    };
  }, []);

  return (
    <PostHogProvider 
      client={posthog}
    >
      <ClerkProvider {...pageProps} publishableKey={CLERK_PUBLISHABLE_KEY}>
        <UserContextProvider>
          <AssistantContextProvider>
            <DashboardContextProvider>
              <NotificationProvider>
                <Notification />
                <Component {...pageProps} />
              </NotificationProvider>
            </DashboardContextProvider>
          </AssistantContextProvider>
        </UserContextProvider>
      </ClerkProvider>
    </PostHogProvider>
  );
}
