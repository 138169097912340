import { createContext, useState, useContext, useEffect } from "react";
import { useAuth } from "@clerk/nextjs";

const AssistantContext = createContext();

export const useAssistantContext = () => useContext(AssistantContext);

export const AssistantContextProvider = ({ children }) => {
  const [latestConversationId, setLatestConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [visuals, setVisuals] = useState([]);
  const [additionalContext, setAdditionalContext] = useState({});
  const { orgId } = useAuth();

  const getLatestConversationId = async () => {
    try {
      const response = await fetch("/api/assistant");
      const data = await response.json();
      if (data && data.conversation_id) {
        setLatestConversationId(data.conversation_id);
      }
      
    } catch (error) {
      console.error("Failed to fetch latest conversation:", error);
    }
  };

  useEffect(() => {
    getLatestConversationId();
  }, [orgId]);  

  useEffect(() => {
    // Get all messages where there are attachments and add that to visuals
    // In a list of lists format where each list contains dicts of {"config":.., "tool_call_id":..}
    const newVisuals = messages.reduce((acc, messageItem) => {
      if (messageItem.attachments && messageItem.attachments.length > 0) {
        const visualsPerMessage = messageItem.attachments.map(attachment => ({
          config: JSON.parse(attachment),
          tool_call_id: messageItem.tool_call_id,
          message_id: messageItem.id,
          idx: messageItem.attachments.indexOf(attachment)
        }));
        acc.push(visualsPerMessage);
      }
      return acc;
    }, []);

    // Only update visuals if they are different from the current state
    if (newVisuals.length !== visuals.length || !newVisuals.every((newVisualGroup, index) => {
      const visualGroup = visuals[index];
      return visualGroup && newVisualGroup.length === visualGroup.length && newVisualGroup.every((newVisual, subIndex) => {
        return JSON.stringify(newVisual) === JSON.stringify(visualGroup[subIndex]);
      });
    })) {
      setVisuals(newVisuals);
    }
  }, [messages, visuals]);

  return (
    <AssistantContext.Provider value={{latestConversationId, getLatestConversationId, messages, setMessages, visuals, setVisuals, additionalContext, setAdditionalContext}}>
      {children}
    </AssistantContext.Provider>
  );
};
