import React, { createContext, useContext, useState } from "react";

type SetStateWithPrev<T> = React.Dispatch<React.SetStateAction<T>>;
type INotification = {
    show: boolean;
    message: string;
    description?: string;
    type: string;
  }
type NotificationContextProps = {
  notification: INotification ;
  setNotification: SetStateWithPrev<INotification>;
}
const NotificationContext = createContext<NotificationContextProps>({} as NotificationContextProps);

type Props = {
  children: JSX.Element | Array<JSX.Element> | React.ReactNode | Array<React.ReactNode>;
}
export const NotificationProvider = ({ children }: Props) => {
  const [notification, setNotification] = useState<INotification>({
    show: false,
    message: "",
    description: "",
    type: ""
  });

  const props: NotificationContextProps = {
    notification, setNotification
  };
  return (
    <NotificationContext.Provider value={props}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }

  return context;
};
