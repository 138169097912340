import type React from "react";
import { createContext, useState, useContext, useEffect } from "react";
import { useAuth } from "@clerk/nextjs";
import { IFivertranFiles, IFivetranConnectorResponse } from "@/types/http/Fivetran";
import { IUserContextData } from "@/types/IUser";

type SetStateWithPrev<T> = React.Dispatch<React.SetStateAction<T>>;
type UserContextProps = {
  allFiles: Array<IFivertranFiles>;
  fetchAllFiles: () => Promise<void>;
  deleteFile: (id: string, type: string) => Promise<void>;
  isLoadingFiles: boolean;
  isUpdatingFiles: boolean;
  setIsUpdatingFiles: (status: boolean) => void;
  userAdditionalInformation: IUserContextData;
  setUserAdditionalInformation: SetStateWithPrev<IUserContextData>;
  isDataReady: boolean;
}
const UserContext = createContext({} as UserContextProps);

export const useUserContext = () => useContext(UserContext);

type Props = {
  children: JSX.Element | Array<JSX.Element> | React.ReactNode | Array<React.ReactNode>;
}
export const UserContextProvider = ({ children }: Props): JSX.Element => {
  const [allFiles, setAllFiles] = useState<Array<IFivertranFiles>>([]); 
  const [isUpdatingFiles, setIsUpdatingFiles] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [userAdditionalInformation, setUserAdditionalInformation] = useState<IUserContextData>({menza_focus: "", extra_info: ""});
  const [isDataReady, setIsDataReady] = useState<boolean>(true);
  const { orgId } = useAuth();

  useEffect(() => {
    fetchAllFiles();
    getUserContext();
  }, [orgId]);  

  const fetchAllFiles = async (): Promise<void> => {
    setIsLoadingFiles(true);
    try {
      const response = await fetch("/api/fivetran/connector");
      const {data_sources_list}: IFivetranConnectorResponse = await response.json();

      if (data_sources_list && data_sources_list.length > 0) {
        setAllFiles(data_sources_list);
        setIsDataReady(data_sources_list.some((file) => file.latest_sync_time !== null));
      } else {
        setAllFiles([]);
        setIsDataReady(false);
      }
    } catch (error) {
      setAllFiles([]);
      setIsDataReady(false);
      console.error("Failed to fetch files:", error);
    } finally {
      setIsLoadingFiles(false);
    }
  };

  const getUserContext = async (): Promise<void> => {
    try {
      const response = await fetch("/api/user/user_context");
      const {context_data}: { context_data: IUserContextData } = await response.json();
      if (context_data) {
        setUserAdditionalInformation(context_data);
      }
    } catch (error) {
      console.error("Failed to fetch user context:", error);
    }
  };

  const deleteFile = async (fileId: string, fileType: string) => {
    setIsUpdatingFiles(true);
    try {
      const requestOption: RequestInit = {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ fileId, fileType }),
      };
      const {status} = await fetch("/api/chat/delete-file", requestOption);
      if (status === 200) {
        return setAllFiles(prevFiles => prevFiles.filter(f => f.id !== fileId));
      }
      console.error("Failed to delete file");
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setIsUpdatingFiles(false);
    }
  };

  const props: UserContextProps = {
    allFiles,
    fetchAllFiles,
    deleteFile,
    isLoadingFiles,
    isUpdatingFiles,
    setIsUpdatingFiles,
    userAdditionalInformation,
    setUserAdditionalInformation,
    isDataReady
  };

  return (
    <UserContext.Provider value={props}>
      {children}
    </UserContext.Provider>
  );
};
